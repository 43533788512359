<template>
    <link rel="preconnect" href="https://fonts.googleapis.com">
    <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin>
    <link href="https://fonts.googleapis.com/css2?family=League+Gothic&display=swap" rel="stylesheet">
    <div class="arg-cont">
      <div class="arg-abierto" data-aos="fade-in">
        <h3>ARGENTINA 🇦🇷🪽</h3>
        <p>Quiero vivir en un país serio, que enfrente los problemas sociales con responsabilidad, sensibilidad y compromiso. Un país que combata la ignorancia con políticas educativas sólidas, que proteja a los más vulnerables y garantice la libertad de todas las personas, sin distinción. Poder expresarse con todas las contradicciones. Porque, como cualquier ser humano, a veces nos gobierna el corazón y otras la razón.</p>
        <p>Sé que estas palabras pueden encontrar eco en quienes buscan respuestas en tiempos de pérdida de fe. No pretenden más que generar esperanza en un nuevo camino. Un camino que tal vez no recorreremos entre iguales, sino junto a quienes son distintos a nosotrxs. Un encuentro que será problemático, pero que también nos permitirá fortalecer el amor. Cada día exige una acción concreta que detenga la rueda del desquicio, del salvajismo, de ese odio que nos consume y nos divide.</p>
        <p>Es urgente romper el patrón de violencia heredado, ese que, generación tras generación, perpetúa el mismo dolor. El país que soñamos comienza cuando entendemos que el progreso es tanto individual como colectivo. Cuando entregamos algo de nosotros mismos para que quienes vienen detrás encuentren un mundo menos hostil. Tal vez ese sea el mayor acto de amor, el que transforme nuestra historia de una vez y para siempre, celebrando con honor el futuro que supimos conseguir.</p>
        <p>Todo árbol empieza su vida bajo tierra. Así crecen también los cambios profundos, en silencio, echando raíces antes de tocar el cielo. No hay transformación sin paciencia, no hay justicia sin amor. Vivimos tiempos donde el odio se viste de certeza y la violencia se confunde con destino. Pero el amor sigue siendo la mayor fuerza de rebelión. Elegirlo es negarse a aceptar la indiferencia, es sostener la esperanza cuando todo nos empuja al desencanto.</p>
        <p>Estas medidas son un pacto con la vida, la decisión de enfrentar la injusticia con valentía, de recuperar lo que nos arrebataron, de abrir caminos donde otros cerraron puertas. Porque un país no se levanta con miedo, se levanta con el coraje de quienes se atreven a soñar juntos. Que nadie te convenza de que nuestro país es un lugar estéril. Somos jardín, no fortaleza. Cuidar lo que crece en nosotros, pero sin negarnos al sol. Este es el momento de sembrar, de imaginar con grandeza, de apostar por un futuro donde la dignidad no sea un privilegio, sino la raíz de nuestra historia.</p>
        <p>A pesar de los golpes de la vida, jamás elegí el camino del odio. Siento empatía por quienes también han sufrido, por aquellos que solo encontraron en la venganza un poco de consuelo a su dolor. Aprendí que todo sirve, que el perdón también es un acto de justicia, que la lucha no es contra nada; la vida se enfrenta con clemencia y dignidad. Aprendí a agradecer porque pude decidir y elegí un camino. Le pido a Dios que no me permita terminar este recorrido sin antes ver a mi pueblo siendo feliz.</p>
        <p>Por eso, mientras la vida me permita caminar entre ustedes, juro entregar mi voz, mi alma, y cada latido de mi corazón para sembrar consuelo, para abrazar con ternura, para sonreír con verdad. Prometo amar hasta que cada alma de este bendito pueblo sienta en su pecho la paz y la alegría que tanto necesita.</p>
      </div>
  
      <div class="contenedor2" data-aos="fade-in">
        <h3>🩵🤍💛</h3>
        <div class="grid-imagenes">
          <div v-for="(propuesta, index) in propuestas" :key="index" class="imagen-container">
            <img :src="propuesta.image" :alt="propuesta.alt" class="imagen-propuesta" />
            <div class="tooltip">{{ propuesta.alt }}</div>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: "ArgCont",
    data() {
      return {
        propuestas: [
          { image: require('@/assets/AI/3.png'), alt: 'Me preocupa la desatención en asuntos cruciales como los recursos naturales, que son un bien colectivo, no mercancía de ningún gobierno. Su entrega bajo el RIGI compromete nuestra soberanía e hipoteca el futuro de las próximas generaciones. Este pueblo tiene historia y su futuro exige un Estado, una Constitución y una democracia. Debemos superar esta crisis, declarar la emergencia ambiental y consolidar un liderazgo que proteja el bienestar colectivo y la sostenibilidad del territorio. 🇦🇷' },
          { image: require('@/assets/AI/4.png'), alt: 'El fascismo no es solo un régimen, es una práctica cotidiana. La mediocridad, la hipocresía y la indiferencia son cómplices de la intolerancia, oxígeno de las tiranías, letargo donde el mal crece en las grietas del silencio, confinando nuestras mentes. El sometimiento también opera por desgaste, con un sistema que asfixia hasta que la angustia se vuelve rutina. No hay libertad ni lucha posible sin asumir el trauma de la crisis. Declarar la emergencia nacional en salud mental es una obligación.' },
          { image: require('@/assets/AI/5.png'), alt: 'Nuevos parámetros económicos deben asegurar salarios y jubilaciones acordes a una base digna de subsistencia. Elevarnos y formar parte de un nuevo mundo es sobrevivir a una era de esclavización tecnológica. Necesitamos el ingreso básico universal automático. El cuidado humano integral (chi) es la base de todos los derechos. Sin cuidados, no hay vida, libertad ni justicia. El derecho al cuidado garantiza protección especial para quienes más lo necesitan: niños, niñas, adultos mayores y pcd.' },
          { image: require('@/assets/AI/6.png'), alt: 'Vuestra Señoría, aferrada a privilegios y rituales anacrónicos, distante de la realidad y de quienes buscan amparo. Un tribunal reducido, homogéneo y ajeno a las  necesidades sociales no puede impartir justicia con equidad. Es tiempo de una justicia democrática. Una Corte amplia, renovada, diversa e independiente, donde juezas y jueces sean elegidos por su competencia, integridad y compromiso con los derechos humanos. No más fueros para la impunidad, no más decisiones a espaldas del pueblo.' },
          { image: require('@/assets/AI/7.png'), alt: 'Joe Lewis, ocupante ilegal del Lago Escondido, símbolo del saqueo y la impunidad, no puede seguir pisando suelo argentino como si nada. Su presencia es una afrenta a la soberanía y a los intereses nacionales. Declararlo indigno y expulsarlo es una deuda con el pueblo argentino. Es hora de sancionar a quienes facilitaron el saqueo y entregaron nuestros recursos estratégicos. La justicia debe actuar para recuperar lo que nos pertenece y aplicar medidas contra quienes traicionaron a la Argentina.' },
          { image: require('@/assets/AI/8.png'), alt: 'La convocatoria a una Asamblea Constituyente propone un proceso de actualización profunda de la Carta Magna, garantizando la participación ciudadana en la construcción de un nuevo marco normativo para la refundación del siglo XXI. Este proceso busca adaptar la Constitución a las necesidades actuales de la Nación, promoviendo inclusión, transparencia y diálogo democrático. Una oportunidad histórica para fortalecer las instituciones y consolidar un proyecto de país justo, moderno y sostenible.' },
          { image: require('@/assets/AI/9.png'), alt: 'La reforma del Poder Ejecutivo propone consolidar ministerios en seis áreas estratégicas: gobierno, futuro, progreso, conocimiento, cuidado y democracia. Esta optimización inteligente busca eliminar la burocracia, mejorar la coordinación interministerial y fortalecer la capacidad operativa del Estado. El objetivo es priorizar la eficiencia, agilizar la toma de decisiones y responder de manera efectiva a los desafíos nacionales, garantizando un manejo más transparente de los recursos públicos.' },
          { image: require('@/assets/AI/10.png'), alt: 'La creación de la CONADEU tiene como finalidad investigar y auditar el endeudamiento histórico del país. Este organismo determinará responsabilidades, sancionará actos de corrupción financiera y propondrá medidas para evitar futuros negociados que comprometan la estabilidad económica. Será un mecanismo de transparencia y rendición de cuentas, enfocado en garantizar la sostenibilidad fiscal y recuperar la confianza en las instituciones. Nunca más a la pérdida de nuestra independencia económica.' },
          { image: require('@/assets/AI/11.png'), alt: 'El Programa Conectar Libertad busca garantizar el acceso equitativo y democrático a la tecnología y la educación digital como un derecho fundamental. A través de la conectividad gratuita en zonas vulnerables, la entrega de dispositivos accesibles y la capacitación masiva, se promoverá la inclusión informática y el desarrollo de habilidades necesarias para competir en el mundo actual. Este programa será clave para reducir la brecha digital y fomentar oportunidades en todo el territorio nacional.' },
          { image: require('@/assets/AI/12.png'), alt: 'La reincorporación de la obra pública como política de Estado promueve la construcción moderna de viviendas sustentables y proyectos estratégicos de infraestructura, como redes energéticas y sistemas hídricos. Estos proyectos generarán trabajo, impulsarán el desarrollo territorial y fortalecerán la integración nacional. La iniciativa combina crecimiento económico con bienestar social, priorizando un impacto positivo en el ambiente, sus ecosistemas y la calidad de vida de toda la población.' },
          { image: require('@/assets/AI/13.png'), alt: 'La criptomonetización argentina promueve el desarrollo de una moneda digital soberana, creada en colaboración con universidades públicas nacionales. Busca modernizar la política monetaria, reducir la intermediación financiera y garantizar transacciones seguras y transparentes. Un paso importante hacia la innovación económica, bajo control y respaldo académico, que permitirá al país adaptarse a las nuevas tendencias globales, fortalecer la educación financiera y afianzar su soberanía política.' },
          { image: require('@/assets/AI/16.png'), alt: 'Recuperación del espacio público como punto de encuentro y celebración de la identidad nacional. Las fechas patrias serán jornadas de unidad, conmemoración y participación activa del pueblo. No serán días vacíos ni feriados sin sentido, sino momentos de reflexión y alegría compartida.  Cultura, música, historia y memoria convivirán en cada plaza, en cada barrio. Volveremos a reconocernos en el abrazo multitudinario, en la emoción de saber que el país es una construcción colectiva.' },
          { image: require('@/assets/AI/17.png'), alt: 'Creación de una estructura de defensa y protección civil con formación en seguridad comunitaria, emergencias y cooperación territorial. Un modelo basado en la soberanía popular y la seguridad humana, donde la organización y la solidaridad sean las principales armas contra la violencia y la desprotección. No será una estructura represiva, sino un escudo de contención para quienes más lo necesitan. Desde el barrio hasta el país, cada comunidad tendrá las herramientas para cuidarse y crecer.' },
          { image: require('@/assets/AI/18.png'), alt: 'La soberanía alimentaria será política de Estado. La alimentación dejará de ser un privilegio para convertirse en un derecho garantizado. Se promoverá la producción nacional, la distribución equitativa y el acceso a alimentos nutritivos a precios justos. Las pequeñas cooperativas y los productores locales serán protagonistas de un sistema donde la comida no sea un negocio de pocos, sino una garantía de dignidad para todos. Comer bien no será un lujo, sino la base de una Argentina sana y fuerte.' },
          { image: require('@/assets/AI/19.png'), alt: 'Descentralización real del poder y desarrollo equitativo de todas las regiones. Se acabará con la lógica centralista que condena al olvido a vastos territorios del país. Hablar de federalismo no será un discurso vacío: significará transferir recursos, autonomía de gestión y diseño de políticas adaptadas a cada realidad territorial. La Argentina crecerá cuando cada provincia en transición ecológica pueda desplegar su potencial, cuando la nación se reconozca a sí misma en cada una de sus regiones.' },
          { image: require('@/assets/AI/20.png'), alt: 'Reafirmación de la soberanía sobre nuestros territorios estratégicos. Desarrollo científico, infraestructura y presencia permanente en la Antártida y el Atlántico Sur. Se garantizará una política de ocupación efectiva, con inversión en tecnología y defensa. La Antártida y Malvinas no serán postales lejanas, sino espacios de acción concreta. Argentina se consolidará como potencia en el Sur global, reafirmando sus derechos históricos con hechos y no solo con palabras.' },
          { image: require('@/assets/AI/21.png'), alt: 'Promoción del diseño y la moda con identidad propia. Se fortalecerá la industria textil, la cultura y el consumo de producción nacional con calidad y valor agregado. Se incentivará la creación de indumentaria con raíces en nuestra historia y nuestra identidad. Argentina tiene el talento, el arte y los recursos para vestirse a sí misma sin depender de las grandes marcas extranjeras. El "hecho en casa" volverá a ser sinónimo de orgullo, calidad y trabajo digno.' },
          { image: require('@/assets/AI/22.png'), alt: 'Reconocimiento pleno de las comunidades indígenas y sus derechos. Protección de lenguas originarias, difusión del folclore y revalorización de la diversidad cultural argentina. La memoria de nuestros pueblos no será una nota al pie en los libros de historia, sino parte viva de la Argentina del futuro.  Se promoverá la educación intercultural, la recuperación de tierras y la autonomía de las comunidades. La Argentina será un país donde todas sus voces tengan lugar, sin excepciones.' },
          { image: require('@/assets/AI/23.png'), alt: 'El odio y las violencias se combaten con políticas de educación y prevención. Se impulsará la formación de juventudes responsables y el fortalecimiento de espacios de contención integral para quienes sufren los efectos de la discriminación. No habrá impunidad para quienes promuevan discursos de odio, exclusión o división entre habitantes del suelo argentino. No se trata solo de sancionar, sino de transformar la sociedad desde la raíz. La justicia y el amor marcarán el rumbo de esta nueva era.' },
          { image: require('@/assets/AI/24.png'), alt: 'El negacionismo de crímenes de lesa humanidad no es una opinión, es un ataque a la memoria, la verdad y la justicia. Se implementarán sanciones efectivas contra quienes relativicen el terrorismo de Estado, la crisis climática y otras violaciones graves a los derechos humanos. Se fortalecerán las políticas de sensibilización, concientización y reparación, garantizando que no haya retrocesos en los derechos conquistados. La democracia no puede convivir con quienes promuevan o legitimen el horror.' },
          { image: require('@/assets/AI/25.png'), alt: 'Reforma integral de todo el sistema educativo con un enfoque en pensamiento crítico, tecnología, historia y cultura popular. Se garantizará una educación pública, gratuita, accesible y de excelencia, formando mentes libres con herramientas para la expansión y el futuro. Así en la tierra como en el cielo, nuestra ciencia será el motor del desarrollo y la soberanía nacional, con investigación aplicada a las necesidades del país. No hay independencia sin conocimiento, no hay futuro sin educación..' }
        ]
      };
    }
  };
  </script>
  
  <style scoped>
  .arg-cont {
    display: flex;
    flex-direction: column;
    margin-top: 3em;
  }
  
  .arg-abierto {
    margin-bottom: 0.1em;
    font-family: "League Gothic";
    font-size: 2rem;
  }
  
  h3 {
    color: var(--color-texto);
    font-family: "League Gothic";
    font-size: 2rem;
    text-align: center;
  }
  
  .contenedor2 {
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
  }
  
  .grid-imagenes {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
    gap: 15px;
    justify-content: center;
    padding: 1em;
  }
  
  .imagen-container {
    position: relative;
    overflow: hidden;
    border-radius: 10px;
    transition: transform 0.3s ease-in-out;
  }
  
  .imagen-container:hover {
    transform: scale(1.05);
    cursor: pointer;
  }
  
  .imagen-propuesta {
    width: 100%;
    height: auto;
    border-radius: 10px;
    transition: opacity 0.3s ease-in-out;
  }
  
  .imagen-container:hover .imagen-propuesta {
    opacity: 0.5;
  }
  
  .tooltip {
    position: absolute;
    bottom: 5%;
    left: 50%;
    transform: translateX(-50%);
    background-color: rgba(0, 0, 0, 0.85);
    color: white;
    padding: 10px;
    border-radius: 5px;
    font-size: 0.5rem;
    width: 90%;
    text-align: center;
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    pointer-events: none;
    font-style: italic;
  }
  
  .imagen-container:hover .tooltip {
    opacity: 1;
  }
  </style>
  