<template>
  <head>
  <link rel="stylesheet" href="https://cdn.jsdelivr.net/npm/bootstrap-icons@1.11.3/font/bootstrap-icons.css">
  <link rel="preconnect" href="https://fonts.googleapis.com" crossorigin>
  <link href="https://fonts.googleapis.com/css2?family=Chakra+Petch:ital,wght@0,300;0,400;0,500;0,600&family=Libre+Barcode+128&family=Lora:ital,wght@0,400..700;1,400..700&family=Playfair+Display:ital,wght@0,400..900;1,400..900&family=Handjet:wght@100..900&family=Shrikhand&family=ABeeZee:ital@0;1&display=swap" rel="stylesheet">
  </head>
  <div class="main-container">
    <header>
      <NavBar/>
      <div class="content" v-if="showContent">
        <router-view :theme="theme" />
      </div>
    </header>
    <main>
      <FooterCont @theme-changed="applyTheme" :theme="theme" />
    </main>
    <div class="banner"></div>
  </div>
</template>

<script>
import NavBar from './components/NavBar.vue';
import FooterCont from './components/FooterCont.vue';
import { nextTick } from 'vue';

export default {
  name: 'App',
  components: {
    NavBar,
    FooterCont,
  },
  data() {
    return {
      theme: 'dark',
      showContent: true,
    };
  },
  mounted() {
    nextTick(() => {
      document.documentElement.setAttribute('data-theme', this.theme);
    });
  },
  methods: {
    applyTheme(theme) {
      this.theme = theme;
      document.documentElement.setAttribute('data-theme', this.theme);
    },
  },
};
</script>

<style>
html, body {
  height: 100%;
  margin: 0;
  display: flex;
  flex-direction: column;
}

.main-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  position: relative;
}

main {
  flex: 1;
}

.banner {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('') no-repeat center center;
  background-size: cover;
  opacity: 0.05;
  z-index: -1;
}

footer {
  z-index: 1;
}
</style>