<template>
  <nav class="navbar" :class="{ scrolled: isScrolled }">

    <div class="nav-icons">
      <router-link to="/" class="logx" aria-label="JulietX">
        <i class="bi bi-patch-check-fill"></i>
      </router-link>
      <a
        href="https://github.com/julietxcalderon"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="GitHub"
      >
        <i class="bi bi-github"></i>
      </a>
      <a
        href="https://behance.net/julietxcalderon/"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="Behance"
      >
        <i class="bi bi-behance"></i>
      </a>
      <a
        href="https://linkedin.com/in/julietxcalderon/"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="LinkedIn"
      >
        <i class="bi bi-linkedin"></i>
      </a>
      <a
        href="https://x.com/julietxcalderon"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="X"
      >
        <i class="bi bi-twitter-x"></i>
      </a>
      <a
        href="https://instagram.com/julietxcalderon"
        target="_blank"
        rel="noopener noreferrer"
        aria-label="Instagram"
      >
        <i class="bi bi-instagram"></i>
      </a>
    </div>
  </nav>
</template>

<script>
export default {
  name: 'NavBar',
  data() {
    return {
      isScrolled: false,
    };
  },
  methods: {
    handleScroll() {
      this.isScrolled = window.scrollY > 50;
    },
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  },
};
</script>

<style scoped>
.navbar {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--color-fondo);
  transition: background-color 0.3s ease, padding 0.3s ease, box-shadow 0.3s ease;
  z-index: 1000;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.navbar.scrolled {
  background-color: var(--color-fondo);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
}

.nav-title {
  text-align: center;
  margin-top: 4em;
  margin-bottom: 2px;
}

.nav-icons {
  display: flex;
  gap: 1.2em;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.nav-icons a {
  font-size: 1.5em;
  color: var(--color-texto);
  transition: color 0.3s ease, transform 0.3s ease;
}

.nav-icons a:hover {
  transform: scale(1.1);
}

@media (max-width: 768px) {
  .nav-icons i {
    font-size: 1.3em;
  }
}

@media (max-width: 480px) {
  .nav-icons i {
    font-size: 1.2em;
  }
}
</style>